.navbar {
  box-shadow: 0 0 5px rgba(#000000, 0.25);
  margin-bottom: 32px;
  .btn-danger {
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
}

.auto-size {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.col-form-label {
  text-align: left;
  opacity: none;
}
.__react_component_tooltip {
  width: 300px;
  background-color: #999;
  text-align: left;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0.5, 0.5);
  opacity: none;
  font-size: 14px;
}
.__react_component_tooltip.show {
  opacity: 1;
}

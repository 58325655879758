.modal {
  background-color: rgba(#000, 0.5);
}
.modal-notify .modal-header {
  border-radius: 3px 3px 0 0;
}
.modal-notify .modal-content {
  border-radius: 3px;
}
.alert {
  font-size: 11px;
  margin: 0;
}

.Login {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#155b86, 0.25);
  background-color: rgba(#000, 0.03);
  &-Blob {
    width: 25%;
    position: absolute;
    z-index: -1;
    opacity: 0.4;
    margin: 75px 0 0 -25px;
  }
  &-Window {
    box-shadow: 0 0 5px rgba(#000, 0.5);
    border-radius: 5px;
    overflow: hidden;
    width: 1200px;
    height: 90%;
    .daisy-logo {
      width: 100%;
      display: block;
      margin: 0 auto 50px;
    }
    @import "semma";
  }
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  @import "overrides";
  @import "optimisation";
  @import "header";
  @import "login";
  h2 {
    margin-bottom: 20px;
  }
  .create-form {
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(#000, 0.25);
    text-align: center;
    margin: 15px 0;
    padding: 32px;
    input {
      border-width: 0 0 2px;
      border-radius: 0;
    }
  }
  .daisy-jobs-table {
    @import "daisytable";
  }
}

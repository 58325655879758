*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
button {
  border: none;
  outline: none;
  background: none;
  font-family: inherit;
}

.tip {
  font-size: 20px;
  margin: 40px auto 50px;
  text-align: center;
}

.cont {
  overflow: hidden;
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  background: #fff;
}

.form {
  overflow: auto;
  position: relative;
  width: 840px;
  height: 100%;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
  padding: 50px 30px;
  &.thanks {
    width: auto;
    text-align: center;
  }
}

.sub-cont {
  overflow: hidden;
  position: absolute;
  left: 840px;
  top: 0;
  width: 1200px;
  height: 100%;
  padding-left: 360px;
  background: #fff;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
}
.cont.s--signup .sub-cont {
  -webkit-transform: translate3d(-840px, 0, 0);
  transform: translate3d(-840px, 0, 0);
}

button {
  display: block;
  margin: 0 auto;
  width: 360px;
  height: 36px;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.img {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 360px;
  height: 100%;
  padding-top: 320px;
}
.img:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1200px;
  height: 100%;
  background-image: url("https://i.imgur.com/RTVDrxG.jpeg");
  background-size: cover;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
}
.img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.cont.s--signup .img:before {
  -webkit-transform: translate3d(840px, 0, 0);
  transform: translate3d(840px, 0, 0);
}
.img__text {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  color: #fff;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
}
.img__text h2 {
  margin-bottom: 10px;
  font-weight: normal;
}
.img__text p {
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
}
.cont.s--signup .img__text.m--up {
  -webkit-transform: translateX(820px);
  transform: translateX(820px);
}
.img__text.m--in {
  -webkit-transform: translateX(-820px);
  transform: translateX(-820px);
}
.cont.s--signup .img__text.m--in {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.img__btn {
  overflow: hidden;
  z-index: 2;
  position: relative;
  width: 100px;
  height: 36px;
  margin: 0 auto;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
}
.img__btn:after {
  content: "";
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 30px;
}
.img__btn span {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 1.2s;
  transition: transform 1.2s;
  transition: transform 1.2s, -webkit-transform 1.2s;
}
.img__btn span.m--in {
  -webkit-transform: translateY(-72px);
  transform: translateY(-72px);
}
.cont.s--signup .img__btn span.m--in {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.cont.s--signup .img__btn span.m--up {
  -webkit-transform: translateY(72px);
  transform: translateY(72px);
}

h2 {
  width: 100%;
  font-size: 26px;
  text-align: center;
}

label {
  display: block;
  width: 360px;
  margin: 25px auto 0;
  text-align: center;
  .ReactPasswordStrength-strength-desc {
    left: 0;
    top: 100%;
    width: auto;
    padding: 5px;
  }
  .ReactPasswordStrength {
    border: 0;
  }
  .ReactPasswordStrength-input {
    border-radius: 0;
    &:focus {
      box-shadow: none;
      border-color: rgba(#000, 0.4);
    }
  }
}
label span {
  font-size: 12px;
  color: #cfcfcf;
  text-transform: uppercase;
  padding-right: 25px;
}

input {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
}
pre {
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
  color: #e40e2a;
}

.forgot-pass {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  color: #145b86;
}

.submit {
  margin-top: 40px;
  margin-bottom: 20px;
  background: #155b86;
  text-transform: uppercase;
}

.fb-btn {
  border: 2px solid #d3dae9;
  color: #8fa1c7;
}
.fb-btn span {
  font-weight: bold;
  color: #455a81;
}

.sign-in {
  transition-timing-function: ease-out;
}
.cont.s--signup .sign-in {
  transition-timing-function: ease-in-out;
  transition-duration: 1.2s;
  -webkit-transform: translate3d(840px, 0, 0);
  transform: translate3d(840px, 0, 0);
}

.sign-up {
  -webkit-transform: translate3d(-1200px, 0, 0);
  transform: translate3d(-1200px, 0, 0);
}
.cont.s--signup .sign-up {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.icon-link {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;
}
.icon-link img {
  width: 100%;
  vertical-align: top;
}
.icon-link--twitter {
  left: auto;
  right: 5px;
}
.ReactPasswordStrength-input:not(:focus).is-password-invalid {
  color: #000000;
}

table {
  overflow: hidden;
  border-radius: 10px;
  font-size: 12pt;
}
th {
  text-transform: uppercase;
  color: #666;
}
.status {
  font-size: 0.85em;
  font-weight: 500;
  border: 1px solid;
  vertical-align: middle;
  padding: 2px 5px;
  border-radius: 10px;
}
.status.complete {
  color: #66f;
  border-color: #99f;
}
.status.pending {
  border-color: #f99;
}
.text-center i {
  border: 1px solid #f99;
  vertical-align: middle;
  border-radius: 5px;
  color: #f33;
  width: 20px;
  padding: 2px 0;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}
.text-center i:hover {
  background-color: #f99;
  color: #fff;
}
